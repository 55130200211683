export const getCaptchaToken = (siteKey: string) => {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    window.grecaptcha.ready(() => {
      try {
        // @ts-ignore
        window.grecaptcha.execute(siteKey, { action: 'submit' }).then(resolve).catch(reject)
      } catch (e) {
        reject(e)
      }
    })
  })
}

export const loadCaptchaScript = (siteKey: string) => {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`
    script.onload = () => {
      // @ts-ignore
      window.grecaptcha.ready(resolve)
    }
    document.head.appendChild(script)
  })
}
