import SuggestionBuilder from '@/services/listingDataService/builders/suggestion'
import type { SuggestionApi, SuggestionClient } from '@/services/listingDataService/types/suggestion'

export default class SuggestionCollectionBuilder {
  static map (data: SuggestionApi.Suggestion | SuggestionApi.Suggestion[]): SuggestionClient.Suggestion | SuggestionClient.Suggestion[] {
    if (Array.isArray(data)) {
      return data.map(item => SuggestionBuilder.build(item))
    } else {
      return SuggestionBuilder.build(data)
    }
  }
}
