import { useHead } from '#imports'
import { useThemeStore } from '@/stores/useThemeStore'

export default defineNuxtPlugin(() => {
  const themeStore = useThemeStore()

  useHead(() => ({
    bodyAttrs: {
      class: themeStore.theme as string
    }
  }))
})
