import type { SuggestionApi, SuggestionClient } from '@/services/listingDataService/types/suggestion'
import { LOCATION_TYPE_LIST } from '@/services/listingDataService/enums'

export default class SuggestionBuilder {
  static build (data: SuggestionApi.Suggestion): SuggestionClient.Suggestion {
    const placeName = {
      [LOCATION_TYPE_LIST.RESIDENTIAL_COMPLEX]: data.residential_complex?.name,
      [LOCATION_TYPE_LIST.NEIGHBORHOOD]: data.neighborhood,
      [LOCATION_TYPE_LIST.ZONE]: data.zone,
      [LOCATION_TYPE_LIST.CITY]: data.city
    }[data.type]

    return {
      city: data.city,
      cityCode: data.city_code,
      country: data.country,
      countryCode: data.country_code,
      id: data.id,
      neighborhood: data.neighborhood,
      neighborhoodCode: data.neighborhood_code,
      residentialComplex: data.residential_complex,
      state: data.state,
      stateCode: data.state_code,
      type: data.type as LOCATION_TYPE_LIST,
      zone: data.zone,
      zoneCode: data.zone_code,
      location: {
        placeName,
        fullLocation: `${data.city}, ${data.country}`
      }
    }
  }
}
