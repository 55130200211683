import type { RouteLocationNormalized } from 'vue-router'
import { useThemeStore } from '@/stores/useThemeStore'
import { BRANDED_VERSION } from '@/stores/useThemeStore/enums'

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized): void => {
  const themeStore = useThemeStore()
  const { $config } = useNuxtApp()
  const theme = to.query.theme || $config.public.appThemeVersion
  if (theme === 'v2') {
    themeStore.setTheme(BRANDED_VERSION.V2)
  } else {
    themeStore.setTheme(BRANDED_VERSION.V1)
  }
})
