export interface User {
  id: string
  name: string
  email: string
  phone: string
}
export interface SignInParams {
  redirectUrl: string
  method?: 'phone' | 'email' | ''
  role?: 'customer' | 'employee' | ''
}

type AuthResult = {
  access_token: string;
  refresh_token: string;
}

export type SessionData = {
  session: string;
  auth_result: AuthResult;
}

export type SignIn = (params: SignInParams) => Promise<void>

export const CURRENT_SESSION_KEY = 'lh_auth_session'
export const REFRESH_TOKEN = 'lh_auth'
