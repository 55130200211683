import type { RouteLocationNormalized } from 'vue-router'
import { useThemeStore } from '@/stores/useThemeStore'
import { useAppConfigStore } from '@/stores/useAppConfig'

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized): void => {
  const validPathList = ['/venta/propiedades']
  const { isV2 } = useThemeStore()
  if (isV2 && validPathList.includes(to.path)) {
    const appConfigStore = useAppConfigStore()
    appConfigStore.setToggleRealEstateSearchNavBar(true)
  }
})
