import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAppConfigStore = defineStore('appConfig', () => {
  const showRealEstateSearchNavBar = ref(false)

  const setToggleRealEstateSearchNavBar = (value: boolean): void => {
    showRealEstateSearchNavBar.value = value
  }

  return {
    showRealEstateSearchNavBar,
    setToggleRealEstateSearchNavBar
  }
})
