import { computed } from 'vue'
import { defineStore } from 'pinia'
import { BRANDED_VERSION } from '@/stores/useThemeStore/enums'

export const useThemeStore = defineStore('theme', () => {
  const themeCookie = useCookie<string|null>('app-theme', {
    default: () => BRANDED_VERSION.V1,
    watch: true
  })

  const setTheme = (theme: BRANDED_VERSION): void => {
    themeCookie.value = theme
  }

  const isV2 = computed((): boolean => themeCookie.value === BRANDED_VERSION.V2)

  return {
    isV2,
    theme: themeCookie,
    setTheme
  }
})
