export type JwtData = {
  aud: string[],
  exp: number,
  [key: string]: unknown
};

export const parseJwt = (token?: string):JwtData| undefined => {
  if (!token) return

  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  } catch (e) {
    return undefined
  }
}

export const getTokenExpirationDate = (token?: string) : Date | undefined => {
  if (!token) return

  const parsedToken = parseJwt(token)

  if (!parsedToken?.exp) return

  return new Date(parsedToken.exp * 1000)
}
